<template>
  <v-card class="teros-elevation" :color="tableColor">
    <v-card-title class="px-5 pb-0 text-uppercase v-title-cadastro">
      {{ titulo }}
    </v-card-title>
    <master-detail
      formTitle="Cadastro de Modelos Incentivados"
      :cols="cols"
      :resourceUrl="resourceUrl"
      descriptionProperty="modelo"
      :canDelete="!canView"
      :canEdit="!canView"
      :canView="canView"
      :hasNewButton="!canView"
      :contextOptions="contextOptions"
      :opts="opts"
      @loadedRows="loadedRows"
    ></master-detail>
    <form-modal
      v-model="addAliquota"
      title="Cadastro de Alíquota Adicional"
      :cols="aliquotaCols"
      :value.sync="selectedItem"
      :opts="opts"
      :errorMessage="aliquotaError"
      @save="doSaveAliquota"
    ></form-modal>
    <kore-modal
      title="Alíquota adicionada com sucesso!"
      :value="aliquotaSuccess"
      @update:value="aliquotaSuccess = $event"
      @confirm="redirectToAliquota()"
    >
      Você gostaria de ir para a página de Cadastro de Alíquota Adicional deste produto?
    </kore-modal>
  </v-card>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { UserTypeEnum } from '@/core/enums/user-types';
import yearsOptions from '@/helpers/yearsOptions';

export default {
  components: {
    "form-modal": () => import("@/components/form-modal.vue"),
    "kore-modal": () => import("@/components/kore-modal.vue"),
    "master-detail": () => import("@/components/master-detail.vue"),
  },
  computed: {
    ...mapGetters(["user"]),
    client: function () {
      return this.getClient();
    },
    canView: function () {
      // return this.$route.meta.viewMode === true;
      return [UserTypeEnum.GERENCIAL_CLIENTE, UserTypeEnum.COLABORADOR].includes(this.user.tipo_usuario);
    },
    cols: function() {
      return [
        {
          key: "modelo",
          name: "Modelo",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "nro_ncm",
          name: "NCM",
          type: this.$fieldTypes.NUMBER,
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "portaria_PPB",
          name: "Portaria PPB",
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "data_PPB",
          name: "Data Portaria PPB",
          type: this.$fieldTypes.DATE,
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "portaria_950",
          name: "Portaria TecNac",
          type: this.$fieldTypes.TEXT,
          colSize: 6,
        },
        {
          key: "data_950",
          name: "Data Portaria TecNac",
          type: this.$fieldTypes.DATE,
          colSize: 6,
        },
        {
          key: "criado_em",
          name: "Criado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
        },
      ];
    },
    contextOptions: function() {
      return [
        {
          name: "Cadastrar Alíquota Adicional",
          limit: 1,
          class: { "btn-info": true, "btn-md": true },
          cb: (e) => {
            this.selectedItem = { pIncentivadoId: e.id, anoBase: this.defaultYearValue };
            this.addAliquota = true;
          },
        },
      ];
    },
    aliquotaCols: function() {
      return [
        {
          key: "pIncentivadoId",
          name: "Modelo",
          type: this.$fieldTypes.SELECT,
          rel: { to: "modelos", key: "id", name: "modelo" },
          rules: [{ rule: "required" }],
          editable: false,
        },
        {
          key: "anoBase",
          name: "Ano Base",
          type: this.$fieldTypes.SELECT,
          // defaultValue: this.$store.getter.getFiltroYear, // TODO substituir linha abaixo quando fizer merge com Melhorar Filtros
          defaultValue: this.defaultYearValue,
          rel: { to: "years", key: "value", name: "text" },
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "aliquota",
          name: "Alíquota",
          type: this.$fieldTypes.DOUBLE,
          rules: [{ rule: "required" }],
          colSize: 6,
        },
      ];
    },
    codigoProduto: function () {
      return this.$route.params.codigoProduto;
    },
    // TODO apagar computed prop quando fizer merge com Melhorar Filtros
    defaultYearValue: function() {
      const ls = this.getFilters('anoBase');
      return ls ? parseInt(ls, 10) : moment().get('year');
    },
    resourceUrl: function () {
      return `/v1/faturamento/produtosincentivados/${this.client.clientId}/${this.codigoProduto}`;
    },
    resourceAliquota: function () {
      return this.apiResource(`/v1/faturamento/aliquota/${this.client.clientId}/produto/${this.codigoProduto}`);
    },
    titulo: function () {
      return `Modelos de ${this.firstRow ? this.firstRow.produto : 'um produto'}`;
    },
  },
  data: function () {
    return {
      addAliquota: false,
      aliquotaError: false,
      aliquotaSuccess: false,
      firstRow: null,
      selectedItem: null,
      opts: {
        modelos: [],
        years: yearsOptions,
      },
    };
  },
  methods: {
    loadedRows: function(rows) {
      if (rows.length) {
        this.opts.modelos = rows;
        this.firstRow = rows[0];
      }
    },
    doSaveAliquota: function(data, closeModal) {
      this.resourceAliquota.save(data).then((response) => {
        if (response.error) {
          this.aliquotaError = response.error;
        } else {
          this.aliquotaError = false;
          closeModal();
          this.aliquotaSuccess = { type: 'confirm' };
        }
      });
    },
    redirectToAliquota: function() {
      this.$router.push({ name: "listagem-aliquota-adicional", params: { codigoProduto: this.codigoProduto } });
    },
  }
};
</script>
